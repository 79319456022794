<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-refresh">刷新</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div>
      <el-table
        :data="tableData"
        border
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index"
          :index="indexMethod">
        </el-table-column>
        <el-table-column
          prop="name"
          label="街道名称">
        </el-table-column>        
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <el-button @click="operatCoin(scope.row, '消费积分')" type="primary" size="mini">消费积分清零</el-button>
            <el-button @click="operatCoin(scope.row, '荣誉积分')" type="danger" size="mini">荣誉积分清零</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { batchOperateUserpoint, batchOperateRypoint } from '@/api/integral'
import { sureMessageBox }　from '@/utils/common'
import {
		getUserArea
	} from '@/api/system'
export default {
  name:"",
  components:{
  },
  props:{},
  data(){
    return {
      tableData: []
    }
  },
  watch:{},
  created(){},
  mounted(){
    this.getList();
  },
  computed:{},
  methods:{
    //获取列表
    getList(){
      getUserArea({
				userid: this.GLOBAL.adminId(),
			}).then(res => {
				this.tableData = res.detail;
			})
    },
    //自定义序号
    indexMethod(index) {
      return index+1;
    },
    //查询
    onSubmit(){
      this.getList();
    },
    //
    operatCoin(row,type){
      console.log(row)
      let pointType = type;
      let data = {
        userid: this.GLOBAL.adminId(),
        communitycode: row.levelcode,
        operate: 3
      }
      sureMessageBox(`是否确认把【${row.name}】的用户【${pointType}】清零？`).then(res=>{

        if(type == '荣誉积分'){
          batchOperateRypoint(data).then(res=>{
            if(res.result == 200){
              this.$message.success('操作成功');
            }else{
              this.$message.error(res.description)
            }
          })
        }else if(type == '消费积分'){
          batchOperateUserpoint(data).then(res=>{
            if(res.result == 200){
              this.$message.success('操作成功');
            }else{
              this.$message.error(res.description)
            }
          })
        }
        
      }).catch(err=>{
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .searchbox {
    margin: 20px 10px;
  }

  .pagebox{
    margin: 20px 0;
    text-align: right;
  }
</style>