import request from '@/utils/request'


// 批量账户积分增减
export function batchOperateUserpoint(data) {
  return request({
    url: '/MA_UserAccount/batchOperateUserpoint',
    method: 'post',
    params: data
  })
}


// 批量荣誉积分增减
export function batchOperateRypoint(data) {
  return request({
    url: '/MA_UserAccount/batchOperateRypoint',
    method: 'post',
    params: data
  })
}
